import { html } from 'htm/preact';
import { render } from 'preact';
import { useContext, useEffect, useReducer, useState } from 'preact/hooks';
import { Router } from 'preact-router';

import { createContext } from 'preact';
import { Model, RootReducer, Product, CartItem, Cart, Action, search, currencySymbol, currency, productsList } from './model';
import { Login, punchout } from './core';

export const DEFAULT_STATE = new Model();

const StoreContext = createContext({
    state: DEFAULT_STATE,      // Your default state.
    dispatch: (action: Action) => { },       // Stubbed. Will be replaced.
});


export const ctx = () => useContext(StoreContext)



const punchoutId = window.location.search
    .substring(1)
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
        (p, n) => {
            p[n[0]] = decodeURIComponent(n[1]);
            return p;
        },
        {} as any,
    ).punchout || localStorage.getItem('punchoutId');

if (punchoutId) {
    localStorage.setItem('punchoutId', punchoutId);
}


function App() {

    const [state, dispatch] = useReducer(RootReducer, DEFAULT_STATE);
    const store = { state, dispatch };

    useEffect(() => {
        dispatch({ type: 'products', products: productsList });

    }, []);


    if (!punchoutId && false) {
        return html`Catalog only available for punchout`;
    } else {
        return html`<${StoreContext.Provider} value=${store}>
    <${Header}></${Header}>

    <${Router}>
        <${Main} default />
        <${CartView} path="/cart" />
        <${ProductDetail} path="/p/:sku" />
        <${Login} path="/login/:punchout_id" />
    </${Router}>
</${StoreContext.Provider}>`;
    }

}


function Main() {



    return html`<div class="app-content content ecommerce-application">

        <${Content}></${Content}>

</div>`;

}


function Sidebar() {



    const { state, dispatch } = ctx();

    const brands = Object.keys(state.products.reduce((p, n) => { p[n.brand] = true; return p; }, {}));

    const categories = Object.keys(state.products.reduce((p, n) => { p[n.category] = true; return p; }, {}));

    return html`<div class="sidebar-detached sidebar-left">
    <div class="sidebar">
        <!-- Ecommerce Sidebar Starts -->
        <div class="sidebar-shop">
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="filter-heading d-none d-lg-block">Filters</h6>
                </div>
            </div>
            <div class="card pt-0">
                <div class="card-body">
                    <!-- Price Filter starts -
                    <div class="multi-range-price">
                        <h6 class="filter-title mt-0">Multi Range</h6>
                        <ul class="list-unstyled price-range" id="price-range">
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceAll" name="price-range" class="form-check-input"
                                        checked="" />
                                    <label class="form-check-label" for="priceAll">All</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange1" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange1">&lt;=$10</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange2" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange2">$10 - $100</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceARange3" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceARange3">$100 - $500</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange4" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange4">&gt;= $500</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Price Filter ends -->

                    <!-- Price Slider starts 
                    <div class="price-slider">
                        <h6 class="filter-title">Price Range</h6>
                        <div class="price-slider">
                            <div class="range-slider mt-2 noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"
                                id="price-slider">
                                <div class="noUi-base">
                                    <div class="noUi-connects">
                                        <div class="noUi-connect"
                                            style="transform: translate(29.2786%, 0px) scale(0.404122, 1);">
                                        </div>
                                    </div>
                                    <div class="noUi-origin" style="transform: translate(-707.214%, 0px); z-index: 5;">
                                        <div class="noUi-handle noUi-handle-lower" data-handle="0" tabindex="0"
                                            role="slider" aria-orientation="horizontal" aria-valuemin="51.0"
                                            aria-valuemax="3500.0" aria-valuenow="1500.0" aria-valuetext="1500">
                                            <div class="noUi-touch-area"></div>
                                            <div class="noUi-tooltip">1500</div>
                                        </div>
                                    </div>
                                    <div class="noUi-origin" style="transform: translate(-303.092%, 0px); z-index: 4;">
                                        <div class="noUi-handle noUi-handle-upper" data-handle="1" tabindex="0"
                                            role="slider" aria-orientation="horizontal" aria-valuemin="1500.0"
                                            aria-valuemax="5000.0" aria-valuenow="3500.0" aria-valuetext="3500">
                                            <div class="noUi-touch-area"></div>
                                            <div class="noUi-tooltip">3500</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Price Range ends -->
                    <div id="clear-filters">
                        <button onclick=${() => dispatch({ type: 'filter.clear' })} type="button" class="btn w-100 btn-primary waves-effect waves-float waves-light">Clear
                            All Filters</button>
                    </div>
                    <!-- Categories Starts -->
                    <div id="product-categories">
                        <h6 class="filter-title">Categories</h6>
                        <ul class="list-unstyled categories-list">
                        <li onclick=${ev => dispatch({ type: 'filter', filter: { ...state.filter, category: undefined } })}>
                                <div class="form-check"  >
                                    <input type="radio" id="category-all" name="category-filter" class="form-check-input"
                                        checked=${state.filter.category == undefined} />
                                    <label class="form-check-label" for="category1">All</label>
                                </div>
                            </li>
                            ${categories.map(category => html`<li onclick=${ev => dispatch({ type: 'filter', filter: { ...state.filter, category } })}>
                                <div class="form-check"  >
                                    <input type="radio" id="category-${category}" name="category-filter" class="form-check-input"
                                        checked=${state.filter.category == category} />
                                    <label class="form-check-label" for="category1">${category}</label>
                                </div>
                            </li>`)}
                        </ul>
                    </div>
                    <!-- Categories Ends -->

                    <!-- Brands starts -->
                    <div class="brands">
                        <h6 class="filter-title">Brands</h6>
                        <ul class="list-unstyled brand-list">
                            ${brands.map((brand, index) => html`
                            <li>
                                <div class="form-check">
                                    <input  onchange=${ev => dispatch({ type: 'filter.in', key: 'brand', option: brand, value: ev.target.checked })} type="checkbox" class="form-check-input" id="productBrand${index}" />
                                    <label class="form-check-label" for="productBrand${index}">${brand}</label>
                                </div>
                                <span></span>
                            </li>`)}
                        </ul>
                    </div>
                    <!-- Brand ends -->

                    <!-- Clear Filters Starts -->
                    
                    <!-- Clear Filters Ends -->
                </div>
            </div>
        </div>
        <!-- Ecommerce Sidebar Ends -->

    </div>
</div>`;
}
function Content() {


    const { state, dispatch } = ctx();


    const products = search(state);

    return html`<div class="content-detached ">
    <div class="content-body">
        <!-- E-commerce Content Section Starts -->
        <section id="ecommerce-header">
            <div class="row">
                <div class="col-sm-12">
                    <div class="ecommerce-header-items">
                        <div class="result-toggler">
                            <button class="navbar-toggler shop-sidebar-toggler" type="button" data-bs-toggle="collapse">
                                <span class="navbar-toggler-icon d-block d-lg-none"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-menu">
                                        <line x1="3" y1="12" x2="21" y2="12"></line>
                                        <line x1="3" y1="6" x2="21" y2="6"></line>
                                        <line x1="3" y1="18" x2="21" y2="18"></line>
                                    </svg></span>
                            </button>
                            <div class="search-results">${products.length} results found</div>
                        </div>
                        <div class="view-options d-flex">
                            <!--
                            <div class="btn-group dropdown-sort">
                                <button type="button" class="btn btn-outline-primary dropdown-toggle me-1 waves-effect"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="active-sorting">Featured</span>
                                </button>
                                <div class="dropdown-menu" style="">
                                    <a class="dropdown-item" href="#">Featured</a>
                                    <a class="dropdown-item" href="#">Lowest</a>
                                    <a class="dropdown-item" href="#">Highest</a>
                                </div>
                            </div>
                            <div class="btn-group" role="group">
                                <input type="radio" class="btn-check" name="radio_options" id="radio_option1"
                                    autocomplete="off" checked="" />
                                <label class="btn btn-icon btn-outline-primary view-btn grid-view-btn waves-effect"
                                    for="radio_option1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-grid font-medium-3">
                                        <rect x="3" y="3" width="7" height="7"></rect>
                                        <rect x="14" y="3" width="7" height="7"></rect>
                                        <rect x="14" y="14" width="7" height="7"></rect>
                                        <rect x="3" y="14" width="7" height="7"></rect>
                                    </svg></label>
                                <input type="radio" class="btn-check" name="radio_options" id="radio_option2"
                                    autocomplete="off" />
                                <label class="btn btn-icon btn-outline-primary view-btn list-view-btn waves-effect"
                                    for="radio_option2"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-list font-medium-3">
                                        <line x1="8" y1="6" x2="21" y2="6"></line>
                                        <line x1="8" y1="12" x2="21" y2="12"></line>
                                        <line x1="8" y1="18" x2="21" y2="18"></line>
                                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                    </svg></label>
                            </div>-->

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- E-commerce Content Section Starts -->

        <!-- background Overlay when sidebar is shown  starts-->
        <div class="body-content-overlay"></div>
        <!-- background Overlay when sidebar is shown  ends-->

        <!-- E-commerce Search Bar Starts -->
        <section id="ecommerce-searchbar" class="ecommerce-searchbar">
            <div class="row mt-1">
                <div class="col-sm-12">
                    <div class="input-group input-group-merge">
                        <input value=${state.search} onkeyup=${ev => dispatch({ type: 'search', value: ev.target.value })} type="text" class="form-control search-product" id="shop-search"
                            placeholder="Search Product" aria-label="Search..." aria-describedby="shop-search" />
                        <span class="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-search text-muted">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg></span>
                    </div>
                </div>

            </div>
        </section>
        <!-- E-commerce Search Bar Ends -->

        <!-- E-commerce Products Starts -->
        <section id="ecommerce-products" class="grid-view">
            ${products.map(ProductCart)}
        </section>
        <!-- E-commerce Products Ends -->

        <!-- E-commerce Pagination Starts
        <section id="ecommerce-pagination">
            <div class="row">
                <div class="col-sm-12">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center mt-2">
                            <li class="page-item prev-item"><a class="page-link" href="#"></a></li>
                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item" aria-current="page"><a class="page-link" href="#">4</a></li>
                            <li class="page-item"><a class="page-link" href="#">5</a></li>
                            <li class="page-item"><a class="page-link" href="#">6</a></li>
                            <li class="page-item"><a class="page-link" href="#">7</a></li>
                            <li class="page-item next-item"><a class="page-link" href="#"></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section> -->
        <!-- E-commerce Pagination Ends -->

    </div>
</div>`;
}


function Header() {
    const { state } = ctx();



    return html`<nav class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center"
    data-nav="brand-center">
    <div class="navbar-header d-xl-block d-none">
        <ul class="nav navbar-nav">
            <li class="nav-item"><a class="navbar-brand"
                    href="/"><span class="brand-logo">
                    </span>
                    <img src=${require('/assets/logo.png')} alt="logo" height="60px" style="width:auto;height:91px;"  />
                </a></li>
        </ul>
    </div>
    <div class="navbar-container d-flex content">

        <ul class="nav navbar-nav align-items-center ms-auto">



            <li class="nav-item dropdown dropdown-cart me-25">
                <a class="nav-link ${state.cartClass}" href="/cart"
                    data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-shopping-cart ficon">
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg><span
                        class="badge rounded-pill bg-primary badge-up cart-item-count">${state.cart.items.reduce((p, n) => p + n.quantity, 0)}</span></a>

            </li>


        </ul>
    </div>
</nav>`;
}


function ProductCart(product: Product) {

    const { dispatch } = ctx();

    return html`<div class="card ecommerce-card">
    <div class="item-img text-center">
        <a href="/p/${product.sku}">
            <img class="img-fluid card-img-top" src=${product.image} alt="img-placeholder" /></a>
    </div>
    <div class="card-body">
        <div class="item-wrapper">
            <div>
                <h6 class="item-price">${currencySymbol(product.currency)}${product.price?.toFixed(2)} EA</h6>
            </div>
        </div>
        <h6 class="item-name">
            <a class="text-body" href="/p/${product.sku}">${product.name}</a>
            <span class="card-text item-company">By <a href="#" class="company-name">${product.brand}</a></span>
        </h6>
        <p class="card-text item-description">${product.description}</p>
    </div>
    <div class="item-options text-center">
        <div class="item-wrapper">
            <div class="item-cost">
                <h4 class="item-price">${currencySymbol(product.currency)}${product.price} EA</h4>
            </div>
        </div>
        <${AddToCartBtn} product=${product}></${AddToCartBtn}>
    </div>
</div>`;
}

function AddToCartBtn({ product }: { product: Product }) {
    const { dispatch } = ctx();
    return html`<a onclick=${() => {
        dispatch({ type: 'add', product, quantity: 1, options: {} });
        setTimeout(() => dispatch({ type: 'endanimate' }), 700);
    }} class="btn btn-primary btn-cart waves-effect waves-float waves-light">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart">
        <circle cx="9" cy="21" r="1"></circle>
        <circle cx="20" cy="21" r="1"></circle>
        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
    </svg>
    <span class="add-to-cart">Add to cart</span>
</a>`;
}
function WhishlistBtn() {
    return html`<a href="#" class="btn btn-light btn-wishlist waves-effect waves-float waves-light">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart">
        <path
            d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
        </path>
    </svg>
    <span>Wishlist</span>
</a>`;
}



function CartView() {

    const { state } = ctx();

    const onPunchoutClick = () => {
        if (!localStorage.getItem('punchout_id')) {
            return alert('You are not in a punchout session. Please start a session from your procurement system.');
        }
        punchout.submitCart({
            items: state.cart.items.map(it => ({ ...it, product: { ...it.product, price: Math.round(it.product.price * 100) } })),
            currency,
        })
            .then(() => { });
    };

    return html`<div class="app-content content ecommerce-application">
        <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
    <div class="bs-stepper checkout-tab-steps">
        <div class="bs-stepper-content">
            <!-- Checkout Place order starts -->
            <div id="step-cart" class="content active dstepper-block" role="tabpanel" aria-labelledby="step-cart-trigger">
                <div id="place-order" class="list-view product-checkout">
                    <!-- Checkout Place Order Left starts -->
                    <div class="checkout-items">
                        ${state.cart.items.map(CartItemView)}

                    </div>
                    <!-- Checkout Place Order Left ends -->

                    <!-- Checkout Place Order Right starts -->
                    <div class="checkout-options">
                        <div class="card">
                            <div class="card-body">
                                <!--
                                <label class="section-label form-label mb-1">Options</label>
                                <div class="coupons input-group input-group-merge">
                                    <input type="text" class="form-control" placeholder="Coupons" aria-label="Coupons" aria-describedby="input-coupons"/>
                                    <span class="input-group-text text-primary ps-1" id="input-coupons">Apply</span>
                                </div>
                                <hr/>
-->
                                <div class="price-details">
                                    <!--
                                    <h6 class="price-title">Price Details</h6>
                                    <ul class="list-unstyled">
                                        <li class="price-detail">
                                            <div class="detail-title">Total MRP</div>
                                            <div class="detail-amt">$598</div>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">Bag Discount</div>
                                            <div class="detail-amt discount-amt text-success">-25$</div>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">Estimated Tax</div>
                                            <div class="detail-amt">$1.3</div>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">EMI Eligibility</div>
                                            <a href="#" class="detail-amt text-primary">Details</a>
                                        </li>
                                        <li class="price-detail">
                                            <div class="detail-title">Delivery Charges</div>
                                            <div class="detail-amt discount-amt text-success">Free</div>
                                        </li>
                                    </ul>
                                    <hr/>
-->
                                    <ul class="list-unstyled">
                                        <li class="price-detail">
                                            <div class="detail-title detail-total">Total</div>
                                            <div class="detail-amt fw-bolder">${currencySymbol(currency)}${state.cart.items.reduce((p, n) => p + n.product.price * n.quantity, 0).toFixed(2)}</div>
                                        </li>
                                    </ul>
                                    <button onclick=${(ev) => {
            ev.preventDefault();
            onPunchoutClick();
        }} type="button" class="btn btn-primary w-100 btn-next place-order waves-effect waves-float waves-light">Punchout</button>
                                </div>
                            </div>
                        </div>
                        <!-- Checkout Place Order Right ends -->
                    </div>
                </div>
                <!-- Checkout Place order Ends -->
            </div>

        </div>
    </div>
        </div>
    </div>
</div>`;
}


function CartItemView(item: CartItem) {

    const { state, dispatch } = ctx();

    return html`<div class="card ecommerce-card">
                            <div class="item-img">
                                <a href="app-ecommerce-details.html">
                                    <img src=${item.product.image} alt="img-placeholder"/>
                                </a>
                            </div>
                            <div class="card-body">
                                <div class="item-name">
                                    <h6 class="mb-0"><a href="app-ecommerce-details.html" class="text-body">${item.product.name}</a></h6>
                                    <span class="item-company">By <a href="#" class="company-name">${item.product.brand}</a></span>
                                </div>
                                <span class="text-success mb-1">In stock - Except sales</span>
                                <div class="item-quantity">
                                    <span class="quantity-title">Qty:</span>
                                    <div class="quantity-counter-wrapper">
                                        <div class="input-group bootstrap-touchspin">
                                            <span class="input-group-btn bootstrap-touchspin-injected">
                                                <button onclick=${() => dispatch({ type: 'quantity', id: item.id, quantity: item.quantity - 1 })} class="btn btn-primary bootstrap-touchspin-down" type="button">-</button>
                                            </span>
                                            <input onkeyup=${ev => dispatch({ type: 'quantity', id: item.id, quantity: parseInt(ev.target.value) })} value=${item.quantity} type="number" class="quantity-counter form-control"/>
                                            <span onclick=${() => dispatch({ type: 'quantity', id: item.id, quantity: item.quantity + 1 })} class="input-group-btn bootstrap-touchspin-injected">
                                                <button class="btn btn-primary bootstrap-touchspin-up" type="button">+</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-options text-center">
                                <div class="item-wrapper">
                                    <div class="item-cost">
                                        <h4 class="item-price">${currencySymbol(item.product.currency)}${item.product.price.toFixed(2)}</h4>
                                    </div>
                                </div>
                                <button onclick=${() => dispatch({ type: 'quantity', id: item.id, quantity: 0 })}  type="button" class="btn btn-light mt-1 remove-wishlist waves-effect waves-float waves-light">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x align-middle me-25"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                    <span>Remove</span>
                                </button>
                            </div>
                        </div>`
}

function ProductPreview(product: Product, addToCart: any) {
    return html`<div>${product.name} :${product.price} EA

    <button onclick=${ev => addToCart(product, 1)}>Add to cart</button>
</div>`;
}
function Product({ productId }: { productId: string }) {

}

function ProductDetail({ sku }: { sku: string }) {

    const { state, dispatch } = ctx();
    const product = state.products.find(it => it.sku == sku);
    if (product) {
        return html`<div class="content-wrapper container-xxl p-0 animate__animated animate__fadeIn">
    
    <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details">
        <div class="card">
            <!-- Product Details starts -->
            <div class="card-body">
                <div class="row my-2">
                    <div class="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0">
                        <div class="d-flex align-items-center justify-content-center">
                            <img src=${product.image} class="img-fluid product-img" alt="product image"/>
                        </div>
                    </div>
                    <div class="col-12 col-md-7">
                        <h4>${product.name}</h4>
                        <span class="card-text item-company">By <a href="#" class="company-name">${product.brand}</a></span>
                        <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                            <h4 class="item-price me-1">${currencySymbol(product.currency)}${product.price} EA</h4>
                            <!--
                            <ul class="unstyled-list list-inline ps-1 border-start">
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star filled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                                <li class="ratings-list-item"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star unfilled-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></li>
                            </ul>
    -->
                        </div>
                        <p class="card-text">Available - <span class="text-success">In stock - Except sales</span></p>
                        <p class="card-text">
                            ${product.description}
                        </p>
                        <!--
                        <ul class="product-features list-unstyled">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg> <span>Free Shipping</span></li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                <span>EMI options available</span>
                            </li>
                        </ul>
    -->
                        <hr/>
                        ${['Group', 'Item', 'Make', 'Model', 'Form Factor', 'Una vez recibida su orden de compra, se confirma fecha de entrega']
                .map(key => html`<div class="product-color-options" style="display:flex">
                            <h6>${key}</h6>
                            <span>${product.data[key]}</span>
                        </div>`)
            }
                        <hr/>
                        <div class="d-flex flex-column flex-sm-row pt-1">
                            <a onclick=${() => dispatch({ type: 'add', product, quantity: 1, options: {} })} class="btn btn-primary btn-cart me-0 me-sm-1 mb-1 mb-sm-0 waves-effect waves-float waves-light">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart me-50"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                <span class="add-to-cart">Add to cart</span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Product Details ends -->

        </div>
    </section>
    <!-- app e-commerce details end -->

</div></div>`;
    } else {
        return html`loading`;
    }
}

render(html`<${App} />`, document.body)
